'use client';
import { useAnalytics } from '@repo/frontend-shared/analytics';
import React from 'react';

/**
 * This is an error boundary on the whole app. More here: https://nextjs.org/docs/app/building-your-application/routing/error-handling
 */
export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const analytics = useAnalytics();

  React.useEffect(() => {
    console.error('ERROR BOUNDARY:', error);
    analytics.trackError(error);
  }, [error, analytics]);

  return (
    <div className="flex items-center justify-center p-10">
      <h2>
        Something went wrong, try to refresh the page or contact an admin.
      </h2>
    </div>
  );
}
