'use client';
import {
  HEADER_CORRELATION_ID_KEY,
  HEADER_SESSION_ID_KEY,
} from '@repo/analytics';
import {
  IAnswerFeedbackResponseDto,
  IApiErrorResponseDto,
  IAssistantResponseDto,
  IChatMessageResponseDto,
  IChatThreadResponseDto,
  ICreateAnswerFeedbackDto,
  ICreateAssistantDto,
  ICreateChatMessageDto,
  ICreateChatThreadDto,
  IInvitationResponseDto,
  IInviteUserToOrganizationDto,
  IMyChatThreadResponseDto,
  IMyUserResponseDto,
  IOrganizationResponseDto,
  IRevokeAssistantAccessDto,
  IUpdateMyUserDto,
  IUpdateUserDto,
  IUserInAssistantAccessListResponseDto,
  IUserResponseDto,
} from '@repo/interfaces';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { v4 as uuidv4 } from 'uuid';

export class APIClient {
  private instance: AxiosInstance;

  private static _singleton: APIClient;

  private constructor() {
    this.instance = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Interceptor to transform dates in the response
    this.instance.interceptors.response.use(
      (response) => {
        response.data = this.transformDateStringsIntoDateObjects(response.data);
        return response;
      },
      (error: AxiosError<IApiErrorResponseDto>) => {
        if (axios.isAxiosError(error)) {
          console.error(
            `${error.message} on url "${error.config?.url}"`,
            error.response,
          );
          return Promise.reject(error.response?.data);
        } else {
          console.error('Unexpected error:', error);
          return Promise.reject(error);
        }
      },
    );

    setupCache(this.instance, {
      ttl: 1000 * 2, // 2 sec of cache, only works on GET requests, it's mostly useful to prevent request with same info to be done again
    });
  }

  public onError(callback: (error: any) => any) {
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        callback(error);
        return Promise.reject(error);
      },
    );
  }

  public static get client(): APIClient {
    if (!APIClient._singleton) {
      APIClient._singleton = new APIClient();
    }

    return APIClient._singleton;
  }

  configure({ baseURL }: { baseURL: string }) {
    this.instance.defaults.baseURL = baseURL;
    return this;
  }

  addSessionIdHeader = (sessionId: string) => {
    this.instance.defaults.headers.common[HEADER_SESSION_ID_KEY] = sessionId;
  };

  addAccessTokenHeader = (accessToken: string) => {
    this.instance.defaults.headers.common['Authorization'] =
      `Bearer ${accessToken}`;
  };

  /* USERS */
  getMyUser = async (): Promise<IMyUserResponseDto> => {
    const response = await this.instance.get<IMyUserResponseDto>('/users/me');
    return response.data;
  };

  getUserOrganization = async ({
    userId,
  }: {
    userId: string;
  }): Promise<IOrganizationResponseDto> => {
    const response = await this.instance.get<IOrganizationResponseDto>(
      `/users/${userId}/organization`,
    );
    return response.data;
  };

  getMyAssistants = async (): Promise<IAssistantResponseDto[]> => {
    const response =
      await this.instance.get<IAssistantResponseDto[]>(`/users/me/assistants`);
    return response.data;
  };

  getAssistant = async (params: {
    assistantId: string;
    organizationId: string;
  }): Promise<IAssistantResponseDto> => {
    const response = await this.instance.get<IAssistantResponseDto>(
      `organizations/${params.organizationId}/assistants/${params.assistantId}`,
    );
    return response.data;
  };

  getUserAssistants = async ({
    userId,
  }: {
    userId?: string;
  }): Promise<IAssistantResponseDto[]> => {
    // TODO: it should not happen but manage this case better, maybe throw an error
    if (!userId) {
      console.error('User ID is missing, cannot get assistants !');
      return [];
    }

    const response = await this.instance.get<IAssistantResponseDto[]>(
      `/users/${userId}/assistants`,
    );
    return response.data;
  };

  getMyChatThreads = async (): Promise<IMyChatThreadResponseDto[]> => {
    const response = await this.instance.get<IMyChatThreadResponseDto[]>(
      `/users/me/chat-threads`,
    );
    return response.data;
  };

  updateMyUser = async (
    updateMyUserDto: IUpdateMyUserDto,
  ): Promise<IMyUserResponseDto> => {
    const response = await this.instance.put<IMyUserResponseDto>(
      `/users/me`,
      updateMyUserDto,
    );
    return response.data;
  };

  updateUser = async ({
    organizationId,
    userId,
    ...updateUserDto
  }: {
    userId: string;
    organizationId: string;
  } & IUpdateUserDto): Promise<IUserResponseDto> => {
    const response = await this.instance.put<IUserResponseDto>(
      `/organizations/${organizationId}/users/${userId}`,
      updateUserDto,
    );
    return response.data;
  };

  /* ORGANIZATIONS */
  getOrganization = async (
    organizationId: string,
  ): Promise<IOrganizationResponseDto> => {
    const response = await this.instance.get<IOrganizationResponseDto>(
      `/organizations/${organizationId}`,
    );
    return response.data;
  };

  getAllOrganizations = async (): Promise<IOrganizationResponseDto[]> => {
    const response =
      await this.instance.get<IOrganizationResponseDto[]>('/organizations');
    return response.data;
  };

  getOrganizationInvitations = async ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<IInvitationResponseDto[]> => {
    const response = await this.instance.get<IInvitationResponseDto[]>(
      `/organizations/${organizationId}/invitations`,
    );

    return response.data;
  };

  getOrganizationUsers = async ({
    organizationId,
  }: {
    organizationId?: string | null;
  }): Promise<IUserResponseDto[]> => {
    // TODO: it should not happen but manage this case better, maybe throw an error
    if (!organizationId) {
      console.error('Organization ID is missing, cannot get users !');
      return [];
    }

    const response = await this.instance.get<IUserResponseDto[]>(
      `/organizations/${organizationId}/users`,
    );

    return response.data;
  };

  getOrganizationAssistants = async ({
    organizationId,
  }: {
    organizationId?: string | null;
  }): Promise<IAssistantResponseDto[]> => {
    // TODO: it should not happen but manage this case better, maybe throw an error
    if (!organizationId) {
      console.error('Organization ID is missing, cannot get assistants !');
      return [];
    }

    const response = await this.instance.get<IAssistantResponseDto[]>(
      `/organizations/${organizationId}/assistants`,
    );

    return response.data;
  };

  inviteUserToOrganization = async ({
    inviteeEmail,
    inviteeFirstName,
    inviteeLastName,
    inviterName,
    assistantIds,
    isOrgAdmin,
    organizationId,
  }: IInviteUserToOrganizationDto & {
    organizationId: string;
  }): Promise<IInvitationResponseDto> => {
    const requestDto: IInviteUserToOrganizationDto = {
      inviteeEmail,
      inviteeFirstName,
      inviteeLastName,
      inviterName,
      assistantIds,
      isOrgAdmin,
    };

    const response: AxiosResponse<IInvitationResponseDto> =
      await this.instance.post(
        `/organizations/${organizationId}/invitations`,
        requestDto,
      );

    return response.data;
  };

  verifyEmail = async ({
    email,
  }: {
    email: string;
  }): Promise<{
    email: string;
    verified: boolean;
  }> => {
    const response = await this.instance.post('/users/verify-email', {
      email,
    });

    return response.data;
  };

  createAssistant = async (
    params: ICreateAssistantDto & { organizationId: string },
  ) => {
    const { organizationId, ...body } = params;

    const response = await this.instance.post(
      `/organizations/${organizationId}/assistants`,
      body,
    );

    return response.data;
  };

  getUsersWhoCanAccessAssistant = async (params: {
    organizationId: string | undefined;
    assistantId: string;
  }): Promise<IUserInAssistantAccessListResponseDto[]> => {
    if (!params.organizationId) {
      throw new Error(
        'Cannot get users who can access assistant: did you forget to provide the organization id?',
      );
    }
    const response = await this.instance.get<
      IUserInAssistantAccessListResponseDto[]
    >(
      `/organizations/${params.organizationId}/assistants/${params.assistantId}/users`,
    );
    return response.data;
  };

  revokeAssistantAccess = async (params: {
    userIds: string[];
    assistantId: string;
    organizationId: string;
  }): Promise<IUserInAssistantAccessListResponseDto[]> => {
    const response = await this.instance.delete<
      IUserInAssistantAccessListResponseDto[],
      AxiosResponse<IUserInAssistantAccessListResponseDto[]>,
      IRevokeAssistantAccessDto
    >(
      `/organizations/${params.organizationId}/assistants/${params.assistantId}/users`,
      {
        data: {
          userIds: params.userIds,
        },
      },
    );
    return response.data;
  };

  /* CHAT THREADS */

  getChatThreadMessages = async ({
    chatThreadId,
  }: {
    chatThreadId: string | null;
  }): Promise<IChatMessageResponseDto[]> => {
    if (!chatThreadId) {
      return [];
    }

    const response = await this.instance.get<IChatMessageResponseDto[]>(
      `/chat-threads/${chatThreadId}/chat-messages`,
    );
    return response.data;
  };

  /* CHAT MESSAGES */

  sendMessageInExistingThread = async ({
    text,
    chatThreadId,
    assistantId,
  }: {
    text: string;
    chatThreadId: string;
    assistantId: string;
  }): Promise<IChatMessageResponseDto> => {
    const correlationId = uuidv4();
    const createDto: ICreateChatMessageDto = {
      text,
      chatThreadId,
      assistantId,
      correlationId,
    };

    const response = await this.instance.post<IChatMessageResponseDto>(
      '/chat-messages',
      createDto,
      {
        headers: {
          // correlationId is also passed in the headers
          // in order to be added to all the logs of the request on the backend
          [HEADER_CORRELATION_ID_KEY]: correlationId,
        },
      },
    );
    return response.data;
  };

  createThreadWithFirstUserMessage = async ({
    firstUserMessageText,
    assistantId,
  }: {
    firstUserMessageText: string;
    assistantId: string;
  }): Promise<IChatThreadResponseDto> => {
    const correlationId = uuidv4();
    const createDto: ICreateChatThreadDto = {
      firstUserMessage: {
        text: firstUserMessageText,
        correlationId,
      },
      assistantId,
    };

    const response = await this.instance.post<IChatThreadResponseDto>(
      '/chat-threads',
      createDto,
      {
        headers: {
          [HEADER_CORRELATION_ID_KEY]: correlationId,
        },
      },
    );
    return response.data;
  };

  /* ANSWER FEEDBACKS */
  createAnswerFeedback = async ({
    assistantMessageId,
    correlationId,
    score,
    label,
    comment,
  }: {
    assistantMessageId: string;
    correlationId: string;
    score: 0 | 100;
    label?: string;
    comment?: string;
  }): Promise<IAnswerFeedbackResponseDto> => {
    const createDto: ICreateAnswerFeedbackDto = {
      assistantMessageId,
      correlationId,
      score,
      label,
      comment,
    };
    const response = await this.instance.post<IAnswerFeedbackResponseDto>(
      '/answer-feedbacks',
      createDto,
      {
        headers: {
          [HEADER_CORRELATION_ID_KEY]: correlationId,
        },
      },
    );
    return response.data;
  };

  /* PRIVATE METHODS */
  private isISODateString = (value: any): boolean => {
    if (typeof value !== 'string') {
      return false;
    }
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
    return isoDatePattern.test(value);
  };

  private isDateObject = (value: any): boolean => {
    return value instanceof Date && !isNaN(value.getTime());
  };

  private transformDateStringsIntoDateObjects = (rawData: any): any => {
    if (Array.isArray(rawData)) {
      return rawData.map((item: any) =>
        this.transformDateStringsIntoDateObjects(item),
      );
    }
    if (
      rawData &&
      typeof rawData === 'object' &&
      // When data comes from cache, date values are already date objects
      !this.isDateObject(rawData)
    ) {
      const transformedData: Record<string, any> = {};
      for (const key of Object.keys(rawData)) {
        const value = rawData[key];
        if (this.isISODateString(value)) {
          transformedData[key] = new Date(value);
        } else if (typeof value === 'object' && value !== null) {
          transformedData[key] =
            this.transformDateStringsIntoDateObjects(value);
        } else {
          transformedData[key] = value;
        }
      }
      return transformedData;
    }
    return rawData;
  };
}

export type APIError = AxiosError;
