import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { APP_TYPE, FrontendEventMap, ORG_GROUPING_KEY } from '@repo/analytics';
import { APIClient } from '@repo/api-client';
import { IOrganizationResponseDto } from '@repo/interfaces';
import posthog from 'posthog-js';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
// NOTE: not importing React makes ts fail, I didn't find a solution to fix it while keeping the Just In Time package structure
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';

export interface AnalyticsService {
  trackEvent<K extends keyof FrontendEventMap>(
    event: K,
    properties: FrontendEventMap[K],
  ): void;
  trackError: (error: unknown) => void;
  identifyUser: (
    userId: string,
    properties: Partial<{
      email: string;
      email_verified: boolean;
      auth0Id: string;
      name: string;
      organization_id: string;
      organization_name: string;
    }>,
  ) => void;
  resetUser: () => void;
}

export const useAnalytics = (): AnalyticsService => {
  const posthog = usePostHog();

  const trackEvent: AnalyticsService['trackEvent'] = (event, properties) => {
    posthog.capture(event, {
      ...properties,
      eventName: event, // we track also the event name as it's more easy to filter based on properties in posthog
      app_type: APP_TYPE,
    });
  };

  const trackError = (error: unknown) => {
    datadogRum.addError(error);
  };

  const resetUser: AnalyticsService['resetUser'] = () => {
    posthog.reset();
    datadogRum.clearUser();
  };

  const identifyUser: AnalyticsService['identifyUser'] = (
    userId,
    properties,
  ) => {
    posthog.identify(userId, {
      // email is a property used by posthog to display the user email instead of the id in the UI
      email: properties.email,
      user_email: properties.email,
      user_name: properties.name,
      auth_id: properties.auth0Id,
    });
    if (properties.organization_id) {
      posthog.group(ORG_GROUPING_KEY, properties.organization_id, {
        id: properties.organization_id,
        // Name: used by posthog interface
        name: properties.organization_name,
        // organization_name: to be consistent with the tracking plan
        organization_name: properties.organization_name,
      });
    }

    datadogRum.setUser({
      email: properties.email,
    });

    trackEvent('Logged In', {});
  };

  return {
    trackEvent,
    trackError,
    resetUser,
    identifyUser,
  };
};

export const InitTracking: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const {
    isAuthenticated,
    user: auth0user,
    getAccessTokenSilently,
  } = useAuth0();
  const analytics = useAnalytics();

  React.useEffect(() => {
    const trackOrResetUser = async () => {
      if (isAuthenticated && auth0user && auth0user.sub) {
        const accessToken = await getAccessTokenSilently();
        APIClient.client.addAccessTokenHeader(accessToken);
        const user = await APIClient.client.getMyUser();
        let organization: IOrganizationResponseDto | undefined = undefined;
        if (user.organizationId) {
          organization = await APIClient.client.getOrganization(
            user.organizationId,
          );
        }

        analytics.identifyUser(user.id, {
          email: user.email,
          email_verified: auth0user?.email_verified,
          auth0Id: auth0user?.sub,
          name: `${user.firstName} ${user.lastName}`,
          organization_id: organization?.id,
          organization_name: organization?.name,
        });
      } else {
        analytics.resetUser();
      }
    };

    void trackOrResetUser();
  }, [isAuthenticated]);

  React.useEffect(() => {
    APIClient.client.onError((error) => {
      analytics.trackError(error);
    });
  }, []);

  return children;
};

/**
 * Provides product analytics and error analytics
 */
export const AnalyticsProvider: FunctionComponent<
  PropsWithChildren<{
    onSessionIdChange: (sessionId: string) => void;
    datadogConfig: {
      isEnabled: boolean;
      applicationId: string | undefined;
      clientToken: string | undefined;
      appName: string;
      appVersion: string | undefined;
      env: string | undefined;
      allowFallbackToLocalStorage?: boolean;
    };
    posthogConfig: {
      key: string | undefined;
      host: string | undefined;
      debugMode: boolean;
    };
  }>
> = ({ children, onSessionIdChange, datadogConfig, posthogConfig }) => {
  useEffect(() => {
    posthog.onSessionId((sessionId) => {
      onSessionIdChange(sessionId);
    });
  }, []);

  useEffect(() => {
    const {
      applicationId,
      clientToken,
      env,
      appName,
      appVersion,
      isEnabled,
      allowFallbackToLocalStorage,
    } = datadogConfig;

    if (isEnabled && (!applicationId || !clientToken || !env)) {
      throw new Error(
        'Cannot initialiaze Datadog: missing application_id, client_token or env',
      );
    }
    if (!isEnabled) return;
    datadogRum.init({
      applicationId: applicationId!,
      clientToken: clientToken!,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: appName,
      env,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: appVersion,
      sessionSampleRate: 100, // Important to have 100% for debugging purposes and also for exhaustivity of errors in production
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      silentMultipleInit: true, // To avoid console errors when website is hot reloaded in dev mode
      allowFallbackToLocalStorage,
      // Specify URLs to propagate trace headers for connection between RUM and backend trace
      //  allowedTracingUrls: [
      //     { match: "https://example.com/api/", propagatorTypes: ["tracecontext"] },
      //   ],
    });
  }, [datadogConfig]);

  useEffect(() => {
    const { key, host, debugMode } = posthogConfig;
    if (!key || !host) return;
    posthog.init(key, {
      api_host: host,
      person_profiles: 'identified_only', // other option is 'always' but it creates too many persons that dont have a business meaning. https://posthog.com/docs/product-analytics/identify#signup-flow-with-frontend-and-backend
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      capture_pageleave: true,
      loaded: (posthog) => {
        /**
         * Enable debug mode if the environment variable is set to true
         * We always set the debug value so that the local storage "debug" value is invalidated
         */
        posthog.debug(debugMode);
      },
    });

    // Should be called after posthog init
    const posthogSession =
      posthog.sessionManager?.checkAndGetSessionAndWindowId(true);
    if (posthogSession?.sessionId) onSessionIdChange(posthogSession.sessionId);
  }, [posthogConfig]);

  return (
    <PostHogProvider client={posthog}>
      <InitTracking>{children}</InitTracking>
    </PostHogProvider>
  );
};
