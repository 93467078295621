// src/index.ts
var HEADER_SESSION_ID_KEY = "x-ph-session-id";
var HEADER_CORRELATION_ID_KEY = "x-correlation-id";
var ORG_GROUPING_KEY = "organization";
var APP_TYPE = "answer";
export {
  APP_TYPE,
  HEADER_CORRELATION_ID_KEY,
  HEADER_SESSION_ID_KEY,
  ORG_GROUPING_KEY
};
