import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AnalyticsStore {
  sessionId: string;
  actions: {
    setSessionId: (sessionId: string) => void;
  };
}

const useAnalyticsStore = create<AnalyticsStore>()(
  devtools((set) => ({
    sessionId: '',
    actions: {
      setSessionId: (sessionId) => {
        set({ sessionId });
      },
    },
  })),
);

// Selectors
export const useAnalyticsSessionId = () =>
  useAnalyticsStore((state) => state.sessionId);

export const useAnalyticsStoreActions = () =>
  useAnalyticsStore((state) => state.actions);
